import { defineStore } from "pinia";

import { useStoresStore } from "./stores";
import { Order } from "~/types/orders";
import { ReportExtension } from "~/types/general";
import {
  InvoiceReport,
  BillReport,
  ConfirmationReport,
  ReportType,
  LabelType,
  PickingListParams,
  ConfirmationListParams,
  SalesSummaryReport,
  StatementReport,
  PickingReport,
  PurchaseInvoiceReport,
  PrintLabelRequest,
  BarcodeStandard,
  StatementReportPayload,
  PurchasePaybackSummaryReport,
  BatchDepositReport,
  RemittanceReport,
} from "~/types/reports";
import { StorePrinter } from "~/types/stores";
import { PurchaseOrder } from "~/types/purchase";

export const useReportsStore = defineStore("reports", () => {
  // State
  const isContentLoaded = ref(false);
  const viewingOrderForPrint = ref<Order | PurchaseOrder>(
    {} as Order | PurchaseOrder
  );
  const invoiceOrderNotes = ref("");
  const preparationOrderNotes = ref("");
  const viewingPurchaseInvoiceForPrint = ref<PurchaseInvoiceReport>(
    {} as PurchaseInvoiceReport
  );
  const viewingInvoiceForPrint = ref<InvoiceReport>({} as InvoiceReport);
  const viewingPaybackSummaryForPrint = ref<PurchasePaybackSummaryReport>(
    {} as PurchasePaybackSummaryReport
  );
  const viewingBatchDepositForPrint = ref<BatchDepositReport>(
    {} as BatchDepositReport
  );
  const viewingRemittanceForPrint = ref<RemittanceReport>(
    {} as RemittanceReport
  );
  const viewingLabelForPrint = ref<any>({});
  const viewingBillForPrint = ref<BillReport>({} as BillReport);
  const viewingPreparationForPrint = ref<PickingReport>({} as PickingReport);
  const viewingConfirmationForPrint = ref<ConfirmationReport>(
    {} as ConfirmationReport
  );
  const viewingSalesSummaryForPrint = ref<SalesSummaryReport>(
    {} as SalesSummaryReport
  );
  const viewingStatementForPrint = ref<StatementReport>({} as StatementReport);
  const selectedPrintLabelData = ref<PrintLabelRequest>(
    {} as PrintLabelRequest
  );
  const selectedItemsIds = ref([]);
  const reportTypesOptions = ref([
    { name: "Invoice", value: ReportType.INVOICE },
    { name: "Service Agreement", value: ReportType.SERVICE_AGREEMENT },
    { name: "Preparation", value: ReportType.PREPARATION },
    { name: "Confirmation", value: ReportType.CONFIRMATION },
    { name: "Sales Summary", value: ReportType.SALES_SUMMARY },
    { name: "Activity", value: ReportType.ACTIVITY },
    { name: "Outstanding", value: ReportType.OUTSTANDING },
    { name: "Payback Summary", value: ReportType.PAYBACK_SUMMARY },
    { name: "Purchase Activity", value: ReportType.PURCHASE_ACTIVITY },
    { name: "Purchase Outstanding", value: ReportType.PURCHASE_OUTSTANDING },
    { name: "Label", value: ReportType.LABEL },
    { name: "Delivery Docket", value: ReportType.DELIVERY },
    { name: "Batch Deposit", value: ReportType.BATCH_DEPOSIT },
    { name: "Remittance", value: ReportType.REMITTANCE },
    { name: "Payback Summary", value: ReportType.PURCHASE_PAYBACK_SUMMARY },
  ]);
  const labelTypesOptions = ref([
    { name: "Supplier Label", value: LabelType.SUPPLIER },
    { name: "Lot Label", value: LabelType.LOT },
    { name: "Barcode", value: LabelType.BARCODE },
  ]);
  const barcodeStandartOptions = ref([
    { name: "C39", value: BarcodeStandard.C39 },
    { name: "C39+", value: BarcodeStandard.C39_PLUS },
    { name: "C39E", value: BarcodeStandard.C39E },
    { name: "C39E+", value: BarcodeStandard.C39E_PLUS },
    { name: "C93", value: BarcodeStandard.C93 },
    { name: "S25", value: BarcodeStandard.S25 },
    { name: "S25+", value: BarcodeStandard.S25_PLUS },
    { name: "I25", value: BarcodeStandard.I25 },
    { name: "I25+", value: BarcodeStandard.I25_PLUS },
    { name: "C128", value: BarcodeStandard.C128 },
    { name: "C128A", value: BarcodeStandard.C128A },
    { name: "C128B", value: BarcodeStandard.C128B },
    { name: "C128C", value: BarcodeStandard.C128C },
    { name: "GS1-128", value: BarcodeStandard.GS1_128 },
    { name: "EAN2", value: BarcodeStandard.EAN2 },
    { name: "EAN5", value: BarcodeStandard.EAN5 },
    { name: "EAN8", value: BarcodeStandard.EAN8 },
    { name: "EAN13", value: BarcodeStandard.EAN13 },
    { name: "UPCA", value: BarcodeStandard.UPCA },
    { name: "UPCE", value: BarcodeStandard.UPCE },
    { name: "MSI", value: BarcodeStandard.MSI },
    { name: "MSI+", value: BarcodeStandard.MSI_PLUS },
    { name: "POSTNET", value: BarcodeStandard.POSTNET },
    { name: "PLANET", value: BarcodeStandard.PLANET },
    { name: "RMS4CC", value: BarcodeStandard.RMS4CC },
    { name: "KIX", value: BarcodeStandard.KIX },
    { name: "IMB", value: BarcodeStandard.IMB },
    { name: "CODABAR", value: BarcodeStandard.CODABAR },
    { name: "CODE11", value: BarcodeStandard.CODE11 },
    { name: "PHARMA", value: BarcodeStandard.PHARMA },
    { name: "PHARMA2T", value: BarcodeStandard.PHARMA2T },
  ]);
  const defaultContentForSupplierLabelPrint =
    ref(`Grown and Packed by [Supplier Name]
[Supplier Address]
Variety: [Product Name]
Packed Date: 03/04/2023
Meets ICA-20 N4103
Product May Contain Sulphur Dioxide
Packed for [Account]`);
  const defaultContentForLotLabelPrint = ref(`Lot # [Lot Number]
[Product Name]
Supplier: [Supplier Name]
Date Received: [Date Received]`);

  // Order Report Functions
  async function printInvoice(id: string | number, body?: any) {
    try {
      const response = await useVaniloApi(`/orders/${id}/invoice`, {
        method: "POST",
        body,
      });
      return response;
    } catch (error) {
      console.log(error);
    }
  }

  async function printOrderConfirmation(
    orderId: string | number,
    body: ConfirmationListParams,
    name?: string
  ) {
    try {
      const response = await useVaniloApi(
        `/orders/${orderId}/${ReportType.CONFIRMATION}`,
        {
          method: "POST",
          body,
          ...(body?.format === ReportExtension.CSV && {
            responseType: "blob",
          }),
        }
      );

      if (body?.format === ReportExtension.CSV && name?.length) {
        const blob = new Blob([response as BlobPart], {
          type: "text/csv",
        });

        const blobUrl = URL.createObjectURL(blob);

        const link = document.createElement("a");

        link.href = blobUrl;
        link.download = `${name}.${ReportExtension.CSV}`;

        document.body.appendChild(link);

        link.click();

        URL.revokeObjectURL(blobUrl);

        return {
          url: blobUrl,
          type: response.type?.split("/")[1],
        };
      }

      return response;
    } catch (error) {
      console.log(error);
    }
  }

  async function printPurchaseOrder(
    orderId: string | number,
    body: any,
    name?: string
  ) {
    try {
      const response = await useVaniloApi(`/purchase-orders/${orderId}/print`, {
        method: "POST",
        body,
        ...(body?.format === ReportExtension.CSV && {
          responseType: "blob",
        }),
      });

      if (body?.format === ReportExtension.CSV && name?.length) {
        const blob = new Blob([response as BlobPart], {
          type: "text/csv",
        });

        const blobUrl = URL.createObjectURL(blob);

        const link = document.createElement("a");

        link.href = blobUrl;
        link.download = `${name}.${ReportExtension.CSV}`;

        document.body.appendChild(link);

        link.click();

        URL.revokeObjectURL(blobUrl);

        return {
          url: blobUrl,
          type: response.type?.split("/")[1],
        };
      }

      return response;
    } catch (error) {
      console.log(error);
    }
  }

  async function printPurchasePaybackSummary(
    orderId: string | number,
    body: any,
    name?: string
  ) {
    try {
      const response = await useVaniloApi(
        `/purchase-orders/${orderId}/print-payback-summary`,
        {
          method: "POST",
          body,
          ...(body?.format === ReportExtension.CSV && {
            responseType: "blob",
          }),
        }
      );

      if (body?.format === ReportExtension.CSV && name?.length) {
        const blob = new Blob([response as BlobPart], {
          type: "text/csv",
        });

        const blobUrl = URL.createObjectURL(blob);

        const link = document.createElement("a");

        link.href = blobUrl;
        link.download = `${name}.${ReportExtension.CSV}`;

        document.body.appendChild(link);

        link.click();

        URL.revokeObjectURL(blobUrl);

        return {
          url: blobUrl,
          type: response.type?.split("/")[1],
        };
      }

      return response;
    } catch (error) {
      console.log(error);
    }
  }

  async function printOrderPreparation(
    orderId: string | number,
    payload: PickingListParams
  ) {
    try {
      const response = await useVaniloApi(
        `/orders/${orderId}/${ReportType.PREPARATION}`,
        {
          params: payload,
          responseType: payload?.format ? ReportExtension.JSON : "blob",
        }
      );

      if (!payload?.format) {
        const blob = new Blob([response as BlobPart], { type: response.type });

        const blobUrl = URL.createObjectURL(blob);

        const link = document.createElement("a");

        link.href = blobUrl;
        link.download = "preparation.pdf";

        document.body.appendChild(link);

        link.click();

        URL.revokeObjectURL(blobUrl);

        return {
          url: blobUrl,
          type: response.type.split("/")[1],
        };
      }

      return response;
    } catch (error) {
      console.log(error);
    }
  }

  async function printSalesSummary(orderId: string | number, format?: string) {
    try {
      const response = await useVaniloApi(`/orders/${orderId}/sales-summary`, {
        params: { format: format },
        responseType: format,
      });

      if (format === ReportExtension.PDF) {
        const blob = new Blob([response as BlobPart], { type: response.type });

        const blobUrl = URL.createObjectURL(blob);
        const link = document.createElement("a");
        link.href = blobUrl;

        link.setAttribute("target", "_blank");
        link.click();

        return {
          url: blobUrl,
          type: response.type.split("/")[1],
        };
      }

      return response;
    } catch (error) {
      console.log(error);
    }
  }

  async function printStatement(
    customerId: string | number,
    type: string,
    body: StatementReportPayload
  ) {
    try {
      const response = await useVaniloApi(
        `/statements/${customerId}/${type}/report`,
        {
          method: "POST",
          body,
          ...(body?.format !== ReportExtension.JSON && {
            responseType: body?.format ? ReportExtension.JSON : "blob",
          }),
        }
      );

      if (body?.format !== ReportExtension.JSON) {
        const blob = new Blob([response as BlobPart], { type: response.type });

        const blobUrl = URL.createObjectURL(blob);

        return {
          url: blobUrl,
          type: response.type.split("/")[1],
        };
      }

      return response;
    } catch (error) {
      console.log(error);
    }
  }

  async function printBatchDeposit(
    invoiceId: string | number,
    body?: StatementReportPayload
  ) {
    try {
      const response = await useVaniloApi(
        `/invoices/${invoiceId}/batch-deposit`,
        {
          method: "GET",
          body,
        }
      );

      return response;
    } catch (error) {
      console.log(error);
    }
  }

  // Printer Functions
  async function getCurrentRegPrinters(options: {
    isSingle?: boolean;
    isPos?: boolean;
    isPreparation?: boolean;
  }): Promise<StorePrinter | StorePrinter[]> {
    try {
      const posLocationRes = await useStoresStore().getSingleStore(
        +localStorage.getItem("pos-location")
      );
      const printers = posLocationRes.data?.printers;

      // get current register printer settings for POS
      const currentRegPrinters = printers?.filter(
        (p) => p.register?.id === +localStorage.getItem("pos-register")
      );

      // common filter function
      const filterCriteria = (p: StorePrinter) => {
        const includeForPreparation = options.isPreparation && p.is_preparation;
        const includeForPos =
          options.isPos &&
          p.is_pos &&
          (options.isPreparation ? p.print_preparation_receipt : true);

        return includeForPreparation || includeForPos;
      };

      // apply logic based on options
      if (options.isSingle) {
        // return the FIRST printer that matches the criteria
        return currentRegPrinters.find(filterCriteria) as StorePrinter;
      }

      // return ALL printers that match the criteria
      return currentRegPrinters.filter(filterCriteria) as StorePrinter[];
    } catch (error) {
      useCatchError(error);
    }
  }

  // PDF
  async function generatePdf(
    html: string,
    options?: {
      base64?: boolean;
    }
  ): Promise<
    | {
        dataUri: string;
      }
    | BlobPart
  > {
    try {
      const res = await $fetch(
        (useConfig().isElectron ? "https://jaffle-nitro.vercel.app" : "") +
          "/api/generate-pdf",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            html,
            options,
          }),
          ...(!options?.base64 && {
            responseType: "blob",
          }),
        }
      );

      return res as { dataUri: string } | BlobPart;
    } catch (error) {
      console.log(error);
    }
  }
  async function downloadPdf(html: string, name: string) {
    try {
      const res = await $fetch("/api/generate-pdf", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          html,
        }),
        responseType: "blob",
      });

      const url = window.URL.createObjectURL(
        new Blob([res as BlobPart], { type: "application/pdf" })
      );
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", name);
      document.body.appendChild(link);
      link.click();
    } catch (error) {
      console.log(error);
    }
  }

  async function markPrinted(orderId: string | number) {
    try {
      return await useVaniloApi(`/orders/${orderId}/mark-printed`, {
        method: "POST",
      });
    } catch (error) {
      useCatchError(error);
    }
  }

  return {
    // State
    isContentLoaded,
    viewingOrderForPrint,
    invoiceOrderNotes,
    preparationOrderNotes,
    viewingPurchaseInvoiceForPrint,
    viewingInvoiceForPrint,
    viewingBatchDepositForPrint,
    viewingLabelForPrint,
    viewingBillForPrint,
    viewingPreparationForPrint,
    viewingConfirmationForPrint,
    viewingSalesSummaryForPrint,
    viewingStatementForPrint,
    selectedPrintLabelData,
    selectedItemsIds,
    reportTypesOptions,
    labelTypesOptions,
    barcodeStandartOptions,
    defaultContentForSupplierLabelPrint,
    defaultContentForLotLabelPrint,
    viewingPaybackSummaryForPrint,
    viewingRemittanceForPrint,

    // Order Report Functions
    printInvoice,
    printOrderConfirmation,
    printPurchaseOrder,
    printOrderPreparation,
    printSalesSummary,
    printStatement,
    printBatchDeposit,
    printPurchasePaybackSummary,

    // Printer Functions
    getCurrentRegPrinters,

    // PDF
    generatePdf,
    downloadPdf,

    markPrinted,
  };
});
